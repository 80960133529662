import { useQuery } from '@apollo/client/react/hooks';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { HeadingBanner } from '../../heading-banner/heading-banner.component';
import { JUST_FOR_YOU_ENTRY, REPORT_JUST_FOR_YOU_ENTRY_CLICK } from '../../../../queries/dynamic-yield/just-for-you-entry.queries';
import { JustForYouEntryQuery, JustForYouEntryQueryVariables } from '../../../../__generated__/graphql-client-types';
import { useTrackDYCampaignImpression } from '../../../../hooks/analytics/analytics.hooks';

export type EntryBannerProps = {
	selector: 'jfy-entry-sidebar' | 'jfy-entry-main';
};
/**
 * Renders the appropriate banner used to get traffic into the Just For You
 * experience.
 */
export const EntryBanner: FunctionComponent<PropsWithChildren<EntryBannerProps>> = ({ selector, children }) => {
	const location = useLocation();
	const { data, loading } = useQuery<JustForYouEntryQuery, JustForYouEntryQueryVariables>(JUST_FOR_YOU_ENTRY, {
		variables: {
			location: location.pathname,
			selector
		}
	});

	const analyticsData = data?.dynamicYieldRecommendations ? data.dynamicYieldRecommendations[0].analyticsData : undefined;
	useTrackDYCampaignImpression(analyticsData, loading);

	if (data?.dynamicYieldRecommendations) {
		const [slot] = data.dynamicYieldRecommendations;
		const layout = selector === 'jfy-entry-main' ? 'split' : 'square';
		const classNames = selector === 'jfy-entry-main' ? 'mb3' : 'pb3';
		if (slot && isValidBannerData(slot)) {
			const {
				title,
				subTitle,
				bannerImage: { id: bannerImageId },
				callToAction,
				decisionId
			} = slot;
			return (
				<div className={classNames}>
					<HeadingBanner
						bannerImageId={bannerImageId}
						heading={title}
						secondHeading={subTitle ?? undefined}
						callToAction={callToAction}
						layout={layout}
						serverAnalytics={{
							mutation: REPORT_JUST_FOR_YOU_ENTRY_CLICK,
							variables: {
								decisionId
							}
						}}
					/>
				</div>
			);
		} else {
			return <>{children}</>;
		}
	} else {
		return <>{children}</>;
	}
};
type ValidBannerData = {
	title: string;
	subTitle: string | null;
	bannerImage: {
		id: string;
	};
	callToAction: {
		url: string;
		name: string;
	};
};
function isValidBannerData(subject: {
	__typename?: 'RecommendationsData';
	title: string | null;
	subTitle: string | null;
	bannerImage: { __typename?: 'Image'; id: string } | null;
	callToAction: { __typename?: 'Link'; url: string; name: string } | null;
}): subject is ValidBannerData {
	return Boolean(subject.title && subject.bannerImage?.id && subject.callToAction?.url);
}
