import React, { FunctionComponent, HTMLAttributes } from 'react';
import { ImageFieldsFragment } from '../../../../../__generated__/graphql-client-types';
import { Link } from '../../../../../components/common-components/link/link.component';
import { StyledLink, LinkStyleColor } from '../../../../../components/common-components/link/styled-link.component';
import { CloudinaryImage } from '../../../../../components/images/cloudinary-image/cloudinary-image.component';
import { ChevronRightIcon } from '../../../../../components/svg/icons.component';
import { CloudinaryOptions } from '../../../../../helpers/cloudinary-helper/cloudinary-helper';

export type CategoryTileProps = {
	id: number;
	name: string;
	url: string;
	image: ImageFieldsFragment;
	tileClass?: string;
	linkColor?: LinkStyleColor;
	showArrow?: boolean;
	showName?: boolean;
	showBorder?: boolean;
	cloudinaryOptions?: CloudinaryOptions;
} & Pick<HTMLAttributes<HTMLElement>, 'className'>;

/**
 * Category Image Tile Component
 */
export const CategoryImageTile: FunctionComponent<CategoryTileProps> = ({
	className = 'ma0',
	name,
	url,
	image,
	tileClass = '',
	showArrow = true,
	showName = true,
	showBorder = true,
	cloudinaryOptions = { width: 140, height: 140 }
}) => {
	const showNameOrArrow = showName || showArrow;

	return (
		<li className={`flex flex-column items-center justify-between w-100 w-25-ns ma0 pa0 ${className}`.trim()}>
			<Link
				url={url}
				automationHook={`${name}-category`}
				className={`${tileClass} flex flex-row flex-column-ns items-center w-100 b--theme-tertiary pv2 pl2 pl0-ns mr2-ns mb0-ns ${
					showBorder && 'ba-ns'
				}`}>
				<CloudinaryImage
					{...image}
					publicID={image.id}
					bypassLazyLoad={true}
					options={cloudinaryOptions}
					useDimensions={false}
					className={`${showNameOrArrow && 'w3'} w-auto-ns`}
				/>
				{showNameOrArrow && (
					<div
						className={`relative-ns flex flex-row flex-column-ns items-center justify-start w-100 bg-theme-white`}
						style={{ height: 52 }}>
						<CategoryTitleArrow name={name} showArrow={showArrow} />
					</div>
				)}
			</Link>
		</li>
	);
};

export type CategoryTitleArrowProps = {
	name: string;
	showArrow?: boolean;
};

export const CategoryTitleArrow: FunctionComponent<CategoryTitleArrowProps> = ({ name, showArrow }) => {
	return (
		<div className="absolute-ns bottom-0 pa2 flex flex-row flex-nowrap items-center justify-between w-100 theme-grey-darker ml2 ml0-ns ">
			<span className={`tl tc-ns w-100`}>{name}</span>
			{showArrow && <ChevronRightIcon className="db dn-ns w-20 f4 theme-secondary mh3" />}
		</div>
	);
};

/**
 * Category Text Tile Component
 */
export const CategoryTextTile: FunctionComponent<Omit<CategoryTileProps, 'image'>> = ({
	className,
	name,
	url,
	tileClass,
	linkColor = 'grey-darker',
	showArrow = false,
	showBorder = false
}) => {
	return (
		<li className={`flex flex-column items-center justify-between w-100 b--theme-tertiary ${className}`.trim()}>
			<StyledLink
				url={url}
				color={linkColor}
				className={`flex flex-row flex-column-ns items-center w-100 h-100 ${showBorder && 'ba-ns b--theme-tertiary'}`}
				underlineHover={true}>
				<div className="flex flex-row flex-nowrap items-center justify-between w-100">
					<span className={`${tileClass} w-100 dn flex-ns dn-l justify-start items-center justify-center`}>{name}</span>
					{/* This span is wrapped in a div because flex doesn't play well with line clamping and overflow */}
					<div className="db dn-ns db-l">
						<span className={`${tileClass} w-100`}>{name}</span>
					</div>
					{showArrow && <ChevronRightIcon className="db dn-ns w-20 f4 theme-secondary mh3" />}
				</div>
			</StyledLink>
		</li>
	);
};
