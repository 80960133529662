import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { GroupContentFieldsFragment } from '../../__generated__/graphql-client-types';
import { verifyGroupsHaveContent } from '../../helpers/category-helper/category.helper';
import { convertConstructFacetsToFacetFilters, findIsSelected, getNonCompatibleFacets } from '../../helpers/content-helper/content.helper';
import { useSearchResults } from '../../hooks/apollo/search/search.hooks';
import { useNavigation } from '../../hooks/navigation/navigation.hooks';
import { GroupContentItem } from '../../types/content.types';
import { ClickableElement } from '../buttons';
import { ResponsiveTab, ResponsiveTabGroup } from '../common-components/responsive-tabs/responsive-tabs.component';
import { ContentContainer } from '../content-container/content-container.component';
import { CloudinaryImage } from '../images/cloudinary-image/cloudinary-image.component';

/**
 * TODO: currently this renders a generic card (similar to category card), when
 * we have component for all card types we need to selectively render based on
 * card type
 *
 * @see https://jira.impdir.com/browse/SODEV-29269
 */
const GroupItem: FunctionComponent<{ item: GroupContentItem }> = ({ item }) => {
	const [isSelected, setIsSelected] = useState(false);
	const { addFacets, removeFacets, results, clearAndAddFacets, loading } = useSearchResults();
	const navigate = useNavigation();
	const { pathname } = useLocation();
	const { facetGroups, selectedFacetGroups } = results ?? {};

	useEffect(() => {
		if (loading) {
			return;
		}
		if (findIsSelected(item.facets, selectedFacetGroups)) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
	}, [item, selectedFacetGroups, loading]);

	const handleClick = () => {
		if (item.url) {
			const parsedUrl = new URL(item.url, 'https://www.build.com');
			if (parsedUrl.pathname !== pathname) {
				navigate(item.url);
				return;
			}
		}

		const facets = convertConstructFacetsToFacetFilters(item.facets);
		if (isSelected) {
			setIsSelected(false);
			removeFacets(facets);
		} else {
			setIsSelected(true);
			const nonCompatibleFacetsArray = getNonCompatibleFacets(item, facetGroups, selectedFacetGroups);
			if (nonCompatibleFacetsArray.length === 0) {
				addFacets(facets);
			} else {
				clearAndAddFacets(facets);
			}
		}
	};

	return (
		<ClickableElement ariaLabel={`${item.title} shop-by`} onClick={handleClick} className="dib w-100-ns">
			<>
				<div className={`${isSelected && 'ba bw1 b--theme-primary'} ma1 mr3 shadow-1-ns h4 tc`}>
					{item.image && (
						<CloudinaryImage
							publicID={item.image.id}
							description={item.image.description}
							options={{ width: 140, height: 140 }}
							useDimensions={false}
							className="h-100"
						/>
					)}
				</div>
				<div className="tc fw6-ns w4 w-auto-ns">{item.title}</div>
			</>
		</ClickableElement>
	);
};

type ResponsiveGroupsProps = Pick<GroupContentProps, 'groups'>;

const ResponsiveGroups: FunctionComponent<ResponsiveGroupsProps> = ({ groups }) => {
	const [firstGroup] = groups;
	return (
		<ResponsiveTabGroup startingTabName={firstGroup.title} tabListClasses="bg-theme-white bb b--theme-tertiary">
			{groups.map(({ title, items }, index) => (
				<ResponsiveTab
					name={title}
					key={title}
					tabClasses={`w-100 pa3 pa2-ns bt br bl b--theme-tertiary flex justify-between items-center f6 f7-ns mw4-ns tc fw6 br--top-ns br2-ns mr1`}
					activeTabClasses="theme-primary bg-theme-white"
					inactiveTabClasses="bg-theme-tertiary-ns"
					analyticsHook={`${title}~${index}`}>
					<div className="b--theme-tertiary bt-ns br bl pa2 flex overflow-x-auto">
						{items.map((item) => (
							<GroupItem item={item} key={item.title} />
						))}
					</div>
				</ResponsiveTab>
			))}
		</ResponsiveTabGroup>
	);
};

export type GroupContentProps = {
	contentId: string;
	groups: GroupContentFieldsFragment[];
};

export const GroupContent: FunctionComponent<GroupContentProps> = ({ contentId, groups }) => {
	return verifyGroupsHaveContent(groups) ? (
		<ContentContainer contentId={contentId}>
			<ResponsiveGroups groups={groups} />
		</ContentContainer>
	) : null;
};
