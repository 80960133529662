import { useQuery } from '@apollo/client/react/hooks';
import loadable from '@loadable/component';
import React, { FunctionComponent, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import {
	CategoryBrowsePageContentQuery,
	CategoryBrowsePageContentQueryVariables,
	CommonPromoFieldsFragment
} from '../../../__generated__/graphql-client-types';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { PageContainer } from '../../../components/common-components/page/page-container/page-container.component';
import { ContentContainer } from '../../../components/content-container/content-container.component';
import { CloudinaryImage } from '../../../components/images/cloudinary-image/cloudinary-image.component';
import { PromoBanner } from '../../../components/promo-banner/promo-banner.component';
import { APPENDED_SITE_NAME, FEATURE_FLAGS } from '../../../constants/general';
import { buildGTMPromoBannerDisplay } from '../../../helpers/analytics/gtm/gtm-utils.helper';
import { isBrandCategory, extractCategoryBrandName, mapPromoBannersByPriority } from '../../../helpers/category-helper/category.helper';
import { getBrandLogoImageRelativeUrl } from '../../../helpers/content-helper/content.helper';
import { useTrackEvent, useTrackPageView } from '../../../hooks/analytics/analytics.hooks';
import { useSetRumViewName } from '../../../hooks/datadog/rum.hooks';
import { DyRecommendationContextType, useDyRecommendationContext } from '../../../hooks/dynamic-yield/dynamic-yield-client.hooks';
import { CATEGORY_BROWSE_PAGE_CONTENT } from '../../../queries/content/content.queries';
import { CategoryBrowseContent, CategoryBrowseContentResult } from '../../../types/content.types';
import { Category, SubCategory } from '../../../types/search.types';
import { useFeature } from '../../../hooks/features/features.hooks';
import { EntryBanner } from '../../../components/dynamic-yield/just-for-you/entry-banner/entry-banner.component';
import { useIsCurrentLocation } from '../../../hooks/routing/is-current-location.hook';
import { CategoryBrowseList } from './components/category-browse-list/category-browse-list.component';
import { CategoryImageTile, CategoryTextTile } from './components/category-tile/category-tile.component';

export const SHOP_ALL_DEPARTMENTS_ROUTE = '/shop-all-departments/c130789';

const LoadableCategoryContent = loadable(
	() => import(/* webpackChunkName: "category-content" */ './components/category-content/category-content.component'),
	{
		resolveComponent: ({ CategoryContent }) => CategoryContent
	}
);

export type CategoryBrowsePageProps = {
	category: Category;
};

function isCategoryPageContent(result?: CategoryBrowseContentResult): result is CategoryBrowseContent {
	return result?.__typename === 'CategoryPageContent';
}

type MaybePromoProps = {
	promo: CommonPromoFieldsFragment | undefined;
};

type BannerProps = {
	bannerType: 'top' | 'secondary';
	promo: CommonPromoFieldsFragment | undefined;
};

/**
 * Renders the promoBanner if available
 */
const MaybePromoBanner: FunctionComponent<MaybePromoProps> = ({ promo }) => (promo ? <PromoBanner {...promo} /> : null);

/**
 * If JFY campaign is active
 *  - and on the Shop All Depts page, renders the top main entry banner if available, otherwise it renders the promo
 *    banner if available.
 *  - when on any other category page, it renders the sidebar entry banner if available, otherwise it renders the
 *    promo banner if available.
 * If JFY campaign is not active, renders the promo banner if available.
 */
const Banner: FunctionComponent<BannerProps> = ({ promo, bannerType }) => {
	const isShopAllDepartmentsPage = useIsCurrentLocation(SHOP_ALL_DEPARTMENTS_ROUTE);
	const isJFYEntryActive = useFeature(FEATURE_FLAGS.JUST_FOR_YOU_ENTRY);
	const isTop = bannerType === 'top';
	if (isJFYEntryActive && isShopAllDepartmentsPage && isTop) {
		return (
			<EntryBanner selector="jfy-entry-main">
				<MaybePromoBanner promo={promo} />
			</EntryBanner>
		);
	} else if (isJFYEntryActive && !isShopAllDepartmentsPage && !isTop) {
		return (
			<EntryBanner selector="jfy-entry-sidebar">
				<MaybePromoBanner promo={promo} />
			</EntryBanner>
		);
	} else if (promo) {
		return <MaybePromoBanner promo={promo} />;
	} else {
		return null;
	}
};

/**
 * Renders the Category page
 */
export const CategoryBrowsePage: FunctionComponent<CategoryBrowsePageProps> = ({ category }) => {
	useDyRecommendationContext({
		type: DyRecommendationContextType.CATEGORY_PAGE,
		data: (category.breadcrumbs || []).map((breadcrumb) => breadcrumb.name)
	});

	const pageName = 'browse:category';

	useTrackPageView({ pageName, ...category.categoryContentOverride }, { page: pageName });
	const trackEvent = useTrackEvent();

	const { data: contentData } = useQuery<CategoryBrowsePageContentQuery, CategoryBrowsePageContentQueryVariables>(
		CATEGORY_BROWSE_PAGE_CONTENT,
		{
			variables: { categoryId: category.id }
		}
	);

	const categoryBrandName = extractCategoryBrandName(category);
	const categoryType = isBrandCategory(category) ? 'brand' : 'category';

	const {
		topPrimary: topPromo,
		topSecondary: topSecondaryPromo,
		bottomPrimary: bottomPromo,
		bottomSecondary: bottomSecondaryPromo
	} = mapPromoBannersByPriority(contentData?.sharedCategoryPromos || []);
	const pageTitle = category.metaData.title || `${category.name}${APPENDED_SITE_NAME}`;
	// TODO: Default category browse description here
	const metaDescription = category.metaData.description || '';
	// Lists for featured vs normal subcategories
	const featuredCategories = category.subCategories?.filter(({ isFeatured }) => isFeatured) || [];
	const nonFeaturedCategories = category.subCategories?.filter(({ isFeatured }) => !isFeatured) || [];

	// Construct Content
	const { categoryContent } = contentData || {};
	const categoryPageContent = isCategoryPageContent(categoryContent) ? categoryContent : null;
	const { brandCopy } = categoryPageContent || {};
	const shouldShowBrandCopy = Boolean(categoryPageContent?.id && brandCopy);
	const { ref: categoryContentRef, inView: categoryContentInView } = useInView({ triggerOnce: true });

	// if no banner exists, then fire off tracking with an empty priority to indicate no banners are displayed
	useEffect(() => {
		if (contentData?.sharedCategoryPromos && !topPromo && !topSecondaryPromo && !bottomPromo && !bottomSecondaryPromo)
			trackEvent(buildGTMPromoBannerDisplay());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentData?.sharedCategoryPromos]);

	useSetRumViewName('Category Browse');

	return (
		<PageContainer canonicalURL={category.url} metaDescription={metaDescription} pageTitle={pageTitle}>
			<>
				{/* BREADCRUMBS */}
				<div className={'flex flex-row flex-wrap justify-between mt2 mb3 w-100'}>
					{category.breadcrumbs && <Breadcrumbs breadcrumbs={category.breadcrumbs} />}
				</div>
				{/* PAGE HEADER */}
				{/* Category title here for non-brand pages */}
				{!categoryBrandName && (
					<div className={'flex flex-wrap justify-between-l mb3'}>
						<div className={'w-100 w-auto-ns'}>
							<h1 className={'dib ma0'}>{category.title}</h1>
						</div>
					</div>
				)}
				{/* PAGE CONTENT */}
				<div className="flex flex-row w-100" data-automation="category-browse-page">
					<div className="dn db-l w-25 mr3">
						{/* Brand Logo (brand categories only)*/}
						{categoryBrandName ? (
							<div className="tc">
								<CloudinaryImage
									publicID={getBrandLogoImageRelativeUrl(categoryBrandName)}
									description={categoryBrandName}
									options={{ width: 0, height: 0 }}
								/>
							</div>
						) : null}
						{/* Secondary (Top) Promo Banner (Construct) */}
						<Banner bannerType="secondary" promo={topSecondaryPromo} />
						{/* Subcategory List Nav */}
						<CategoryBrowseList
							title={`Browse ${category.name}`}
							items={category.subCategories}
							itemTransform={(categoryProps) => (
								<CategoryTextTile
									{...categoryProps}
									className="bl-l br-l bb-l ph3 pv2"
									key={categoryProps.id}
									tileClass="tl tc1-title"
								/>
							)}
						/>
						{/* Secondary (Bottom) Promo Banner (Construct) */}
						{bottomSecondaryPromo && <PromoBanner {...bottomSecondaryPromo} />}
						<div id={`dy-recs-${categoryType}-browse-page-vertical`} data-testid="dy-recs-placement-vertical" />
					</div>
					<div className="w-100 w-75-l ml2-l">
						{/* Category title here for brand pages */}
						{categoryBrandName && <h1 className={'dib ma0 mb4'}>{category.name}</h1>}
						{/* BRAND COPY */}
						{shouldShowBrandCopy && (
							<ContentContainer contentId={categoryPageContent?.id || ''}>
								<p>{brandCopy}</p>
							</ContentContainer>
						)}
						{/* Category Promo Banner (Construct) */}
						<Banner bannerType="top" promo={topPromo} />
						{/* Featured Categories */}
						<CategoryBrowseList
							className="flex-row-ns flex-wrap items-center justify-start ph0 w-auto w-100-ns"
							items={featuredCategories}
							itemTransform={(categoryProps, index, info) => (
								<CategoryImageTile
									{...categoryProps}
									className={'ph2-ns mb3'}
									key={categoryProps.id}
									tileClass={`${!info.isLast ? 'bb' : ''}`}
								/>
							)}
						/>
						<div id={`dy-recs-${categoryType}-browse-page-2`} data-testid="dy-recs-placement-2" />
						{/* Shop By Section */}
						{categoryPageContent?.categories?.map((shopSection, index) => {
							return (
								<div key={index} className="mv6">
									<h3>Shop by {shopSection.title}</h3>
									<CategoryBrowseList
										className="flex-row-ns flex-wrap items-center justify-start ph0 w-auto w-100-ns"
										items={shopSection.items as SubCategory[]}
										itemTransform={(categoryProps, itemIndex, info) => (
											<CategoryImageTile
												{...categoryProps}
												className="ph2 mb3"
												key={`${categoryProps.id}|${categoryProps.name}`}
												tileClass={`${!info.isLast ? 'bb' : ''}`}
											/>
										)}
									/>
								</div>
							);
						})}
						{/* NonFeatured Categories (small displays) */}
						{nonFeaturedCategories.length > 0 && (
							<div className="db dn-l">
								<CategoryBrowseList
									className="flex-row-ns flex-wrap items-center justify-start ph0 w-auto w-100-ns"
									title={`Shop More ${category.name}`}
									titleClass={`ba-l b--theme-tertiary ph2 pv3`}
									items={nonFeaturedCategories}
									itemTransform={(categoryProps, index, info) => (
										<CategoryTextTile
											{...categoryProps}
											className={`w-25-ns ph2 pv3 pv0-ns mb3-ns mb0-l ${!info.isLast ? 'bb bn-ns' : ''}`}
											key={categoryProps.id}
											showArrow={true}
											showBorder={true}
											tileClass={`ph3-ns pv3-ns tc-ns tc1-title tc3-title-ns tc1-title-l h3-ns`}
										/>
									)}
								/>
							</div>
						)}
						<div ref={categoryContentRef}>
							{categoryContent && categoryContentInView && (
								<LoadableCategoryContent categoryName={category.name} content={categoryPageContent} promo={bottomPromo} />
							)}
						</div>
					</div>
				</div>
				<section id={`dy-recs-${categoryType}-browse-page-1`} data-testid="dy-recs-placement-1"></section>
			</>
		</PageContainer>
	);
};
