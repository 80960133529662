import React, { FunctionComponent, PropsWithChildren } from 'react';
import { HOMEPAGE_LINK } from '../../constants/links';
import { generateDataSelector } from '../../helpers/general-helper/general-helper';
import { ChevronRightIcon, HomeIcon } from '../svg/icons.component';
import { StyledLink } from '../common-components/link/styled-link.component';

export type Breadcrumb = {
	name: string;
	url?: string;
};

export type BreadcrumbsProps = {
	breadcrumbs: Breadcrumb[];
	homePageLink?: string; // used for instances when we wish to override the home icon link such as shop by look
};

type BreadcrumbItemProp = {
	breadcrumb?: Breadcrumb;
	position: string;
};

const BreadcrumbItem: FunctionComponent<PropsWithChildren<BreadcrumbItemProp>> = ({ children, position, breadcrumb }): JSX.Element => {
	let breadcrumbElement: React.ReactNode | HTMLSpanElement | null = null;

	if (breadcrumb) {
		if (breadcrumb.url) {
			breadcrumbElement = (
				<StyledLink itemProp="item" underlineHover={true} url={breadcrumb.url}>
					<span itemProp="name">{breadcrumb.name}</span>
				</StyledLink>
			);
		} else if (breadcrumb.name) {
			breadcrumbElement = (
				<span itemProp="name" aria-current="page">
					{breadcrumb.name}
				</span>
			);
		}
	}

	return (
		<li
			className={breadcrumbElement ? 'mb1' : ''}
			itemScope={true}
			itemProp="itemListElement"
			itemType="https://schema.org/ListItem"
			data-automation={generateDataSelector('breadcrumb', breadcrumb?.name)}>
			{breadcrumbElement}
			<meta itemProp="position" content={position} />
			{children}
		</li>
	);
};

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ breadcrumbs, homePageLink }) => {
	return (
		<nav aria-label="Breadcrumb">
			<ol className="flex flex-wrap list ma0 pa0 f6 items-center" itemScope={true} itemType="https://schema.org/BreadcrumbList">
				<BreadcrumbItem position="1">
					<StyledLink itemProp="item" url={homePageLink ? homePageLink : HOMEPAGE_LINK} ariaLabel="Return to Home">
						<HomeIcon className="f4" />
						<meta itemProp="name" content="Home" />
					</StyledLink>
				</BreadcrumbItem>
				{breadcrumbs.map((breadcrumb, index) => {
					return (
						<React.Fragment key={breadcrumb.name}>
							<span className="mh1" aria-hidden={true}>
								<ChevronRightIcon />
							</span>
							<BreadcrumbItem position={(index + 2).toString()} breadcrumb={breadcrumb} />
						</React.Fragment>
					);
				})}
			</ol>
		</nav>
	);
};
